<template>
  <div class="page-content">
    <div class="container">
      <manual-link :href="manuallink">{{ t('商品ページ生成・改善') }}</manual-link>
      <iframe src="https://udify.app/chatbot/gDEeGtmhLTSMLOw5" style="width: 100%; height: 100%; min-height: 700px" frameborder="0" allow="microphone"></iframe>
    </div>
  </div>
</template>

<script>
import ManualLink from '@/components/ManualLink.vue';
import Utils from '@/mixins/utils';
import AuthUtil from '@/mixins/authutil';

const MANUAL_JP = 'https://pro.eresa.jp/function/product-page-generator/';
const MANUAL_COM = 'https://eresa.io/function/product-page-generator/';

export default {
  name: 'PageGenerator',
  components: {
    ManualLink,
  },
  mixins: [Utils, AuthUtil],
  data() {
    return {
      manuallink: this.isComEresa() ? MANUAL_COM : MANUAL_JP,
    }
  },
  async mounted() {
    await this.validateSubscriber();
  },
  methods: {
  },
}
</script>

<style scoped>
</style>
